import { useRef, useState } from 'react';

function Dashboard () {
    const ref = useRef(null);

  return (
    <div className='dashboard-page'>
      <div className="dashboard-content">
      </div>
    </div>
  )
}

export default Dashboard
