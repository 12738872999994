import { useEffect, useRef, useState } from 'react'
import Input from './_Components/Input'
import { Link, useNavigate, useParams } from 'react-router-dom'
import call from '../../Methods.js'
import { use } from '../../Helpers/Helpers.js'
import Variables from '../../global.js'
import { useSelector } from 'react-redux'
import Loading from './_Components/Loading.js'

function Businesses () {
  const { id } = useParams()
  const [modalState, setModalState] = useState(false)
  const [businesses, setBusinesses] = useState(null)
  const { loggedIn, user } = useSelector(state => state.auth)
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    let response = await call.forLoggedUser.business.getBusinesses({})
    if(response){
      setBusinesses(response)
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!modalState) {
      loadData()
      setModalState(false);
    }
  }, [modalState]);

  const closeModal = (event) =>{
    if(event.key=='Escape'){
      navigate('/admin/businesses',{replace:true})
    }
  }

  useEffect(() => {
    if (!id) {
      loadData()
      document.body.style.overflowY = 'auto'
    } else {
      document.body.style.overflowY = 'hidden'
      //document.addEventListener('keyup',closeModal,true);
    }
    //return ()=>document.removeEventListener('keyup',closeModal,true)
  }, [id])

  if(loading) return <Loading/>
  return (
    <div className='businesses-page owner-page'>
      <div className='businesses-list'>
        <h1 className='page-header'>
        <span className="left-container">
            <Link id="previous-page" to={`/owner/dashboard/`}>
            </Link>
          Kateqoriyalar
          </span>
          <Link
            to={`/owner/businesses/0`}
            id='add-new'
            onClick={() => setModalState(true)}
          >
            Yenisini əlavə et
          </Link>
        </h1>
        <table className='admin-table'>
          <thead>
            <tr>
              <th>
                <div>№</div>
              </th>
              <th>
                <div>Biznesin adı</div>
              </th>
              <th>
                <div>Şəkil</div>
              </th>
              <th>
                <div>Sıralama</div>
              </th>
              <th>
                <div>Funksiyalar</div>
              </th>
            </tr>
          </thead>
          {businesses && (
            <tbody>
              {businesses
                .sort((a, b) => +a.order - +b.order)
                .map((b, index) => {
                  return (
                    <tr
                      key={use.newUid()}
                      className={`item${b.statusId <= 0 ? ' deactive' : ''}`}
                    >
                      <td width='12%' className='order'>
                        {index + 1}
                      </td>
                      <td width='30%' className='name'>
                        {b.name}
                      </td>
                      <td width='20%' className='image'>
                        <img
                          src={`${Variables.base_url}/api/Images/Business/thumbnail/${b.imageUrl}`}
                        />
                      </td>
                      <td width='12%' className='order'>
                        {b.order}
                      </td>
                      <td width='38%' className='actions'>
                        <Link
                          className='action-button edit'
                          to={`/owner/businesses/${b.id}`}
                          onClick={() => setModalState(true)}
                        >
                          🖊 Düzəliş
                        </Link>
                        <Link
                          to={`/owner/businesses/${b.id}/items`}
                          className='action-button products'
                        >
                          {'Kateqoriyalar'}
                        </Link>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          )}
        </table>
      </div>
      <BusinessModal businessId={id} />
    </div>
  )
}

function BusinessModal ({ businessId = null }) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [business, setBusiness] = useState({})
  const fileInputRef = useRef(),
    formRef = useRef(),
    previewImageRef = useRef()

  if (businessId != null && typeof +businessId !== 'number') {
    businessId = 0
  }
  
  const loadData = async () => {
    setLoading(true)
    if (businessId != 0) {
      const response = await call.forLoggedUser.business.getBusiness({
        id: businessId
      })
      if (response) {
        setBusiness(response);
      } else {
        navigate('/owner/businesses', { replace: true })
      }
    } else {
      setBusiness({})
    }
    setLoading(false)
  }

  useEffect(() => {
    if (businessId != null) {
      loadData().catch(err => console.log(err))
    } else {
      formRef.current.reset()
    }
  }, [businessId])

  const updateImage = () => {
    fileInputRef.current.click()
  }

  const changePreviewImage = async e => {
    setLoading(true)
    let data = await use.getImageObject(e)
    let _business = business;
    _business.image = data
    setBusiness(_business)
    setLoading(false)
  }

  const saveData = async businessId => {
    setLoading(true)
    let _business, businessResponse
    
    _business = {
      name: use.getInputValueByName('name'),
      order: use.getInputValueByName('order'),
      statusId: use.getInputValueByName('statusId'),
      image: business.image
    }

    if (businessId != 0) {
      _business.id = businessId
      console.log(business,businessId)
      businessResponse = await call.forLoggedUser.business.update(_business)
    } else {
      _business.id = null;
      _business.imageUrl = _business.image? null : 'menyumuz-online-logo.png';
      businessResponse = await call.forLoggedUser.business.add({..._business})
    }
    setLoading(false)
    if (businessResponse) {
      setBusiness(businessResponse)
      //navigate('/client/categories', { replace: true })
    }
  }

  const deleteData = async businessId => {
    let sure = window.confirm('Əminsinizmi?')
    if (sure) {
      const response = await call.forLoggedUser.business.update({
        id: businessId,
        statusId: -1
      })
      if (response) {
        alert('Silindi')
        navigate('/client/categories', { replace: true })
      } 
    }
  }
  return (
    <>
      <div
        id='business-edit'
        className={`modal-container ${businessId != null ? 'open' : null}`}
      >
        <div className='business-form side-modal'>
          <header className='side-modal-header'>
            <span
              className='close-side-modal'
              onClick={() => {
                navigate('/client/categories', { replace: false })
              }}
            >
              &times;
            </span>
            <h3>{business?.name}</h3>
          </header>
          <form className='form row' ref={formRef}>
            <input
              name={''}
              ref={fileInputRef}
              accept={'image/png, image/jpeg'}
              style={{ display: 'none' }}
              type='file'
              onChange={e => changePreviewImage(e)}
            />
            <div className='image-input-container icon-container'>
              <div className='image' onClick={updateImage}>
                <div className='edit-layer'>
                  <span className='pen'>✎</span>
                </div>
                {!business?.imageUrl && (
                  <img src={'/Images/menyumuz-online-logo.png'} alt={'menyumuzonline logo'} />
                )}
                {!loading && business && (
                  <img
                    ref={previewImageRef}
                    src={
                      business.image?.isNew
                        ? business.image.url
                        : `${Variables.base_url}/api/Images/Business/Medium/${business.imageUrl}`
                    }
                    alt={business.name}
                  />
                )}
                
              </div>
            </div>
            <Input
              fieldName={'name'}
              label={'Kateqoriyanın adı'}
              type={'text'}
              value={business?.name}
            />
            <Input
              label={'Görünmə sırası'}
              fieldName={'order'}
              type={'number'}
              style={{ textAlign: 'center' }}
              containerStyle={{ width: '40%', display: 'inline-block' }}
              value={business?.order}
            />
            <Input
              label={'Aktiv'}
              type={'checkbox'}
              fieldName={'statusId'}
              containerStyle={{
                width: '40%',
                textAlign: 'center',
                display: 'inline-block'
              }}
              value={business?.statusId}
            />
            {businessId != 0 && (
              <div
                className='button-box'
                style={{ width: '20%', display: 'inline-block' }}
              >
                <button
                  type='button'
                  className='button button-delete'
                  onClick={() => deleteData(businessId)}
                >
                  Sil
                </button>
              </div>
            )}
            <div className='button-box'>
              <button
                type='button'
                className='button button-back'
                onClick={() =>
                  navigate('/client/categories', { replace: true })
                }
              >
                İmtina
              </button>
              {loading ? (
                <button
                  type='button'
                  disabled
                  className='button button-loading'
                >
                  Bir saniyə...
                </button>
              ) : (
                <button
                  type='button'
                  className='button button-submit'
                  onClick={() => saveData(businessId)}
                >
                  Təsdiq
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Businesses
