import call from '../Methods.js'

export const use = {
  precisionRound: (number, precision) => {
    var factor = Math.pow(10, precision)
    return Math.round(number * factor) / factor
  },
  newUid: () => {
    return window.crypto.randomUUID().split('-')[0]
  },
  token: () => {
    return localStorage.getItem('mo_token')
  },
  entryKey: async () => {
    let result = await call.user.checkToken(use.token())

    return use.token() ? result?.success : false
  },
  redirectToDashboard: async () => {},
  getImageObject: async e => {
    async function readAsDataURL (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(file)
      })
    }
    const file = e.target.files[0]
    let imageObj = {}
    if (file != null) {
      const result = await readAsDataURL(file);
      imageObj = {
        base64: result,
        url: URL.createObjectURL(file),
        isNew: true,
        size:file.size
      }
    }

    return imageObj
  },
  normalizePrecision: number => {
    return Math.round(number * 100) / 100
  },
  userSettings: () => {
    return JSON.parse(localStorage.getItem('tpp_userSettings'))
  },
  userShort: async () => {
    let userInfo = JSON.parse(sessionStorage.getItem('tpp_userInfo'))
    if (!userInfo) {
      let token = use.token()
      if (token) {
        const _userInfoRequest = await call.user.checkToken(token)
        if (_userInfoRequest.success) {
          userInfo = _userInfoRequest.data
        }
      }
    }
    return userInfo
  },
  getInputValueByName: (name, parent = document) => {
    let element = parent.querySelectorAll(`body [name="${name}"]`)
    if (element.length === 1) {
      switch (element[0].type) {
        case 'checkbox':
          return Number(element[0].checked)
          break;
        default:
          return element[0].value || element[0].text
      }
    } else if (element.length > 1) {
      if(element[0].type=='radio'){
        let selected = document.querySelector(`body [name="${name}"]:checked`);
        return selected?.value;
      }
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  },
  toggleFormInput: formId => {},
  resetUserLoginInfo: () => {
    localStorage.removeItem('tpp_token')
    sessionStorage.removeItem('tpp_userInfo')
  },
  statusColors: () => {
    let colors = {}
    colors.forOrders = {
      '-2': '#ff5252',
      '-1': '#fcba03',
      0: '#FF9800',
      1: '#4242c4',
      2: '#c442c4',
      3: '#42c442',
      4: '#c4c442',
      5: '#42c490',
      6: '#42c4c4',
      7: '#ff5252',
      8: '#fcba03',
      9: '#ff9800'
    }
    return colors
  },
  convertPriceDataToArray: (priceStr) =>{
    return priceStr.split('##').map(p => {
      let splitted = p.split('**')
      return { name: splitted[0], price: splitted[1]}
    })
  }
}
