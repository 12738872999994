import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Variables from '../../../global'

function Item ({ item }) {
  const [loading,setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const showImages = useSelector(state => state.menu.showProductImages)

  useEffect(()=>{
    if(showImages==0){
      setLoading(false);
    }
  },[])
  
  return (
    <React.Fragment>
      <div className='item-info' onClick={() => setModalState(true)}>

        {showImages==1 && <div className='item-image'>
          <img src={`${Variables.base_url}/api/images/business/thumbnail/${item.imageUrl}`} loading='lazy'  />
        </div>}
        <div className='item-name'>
          {item.name}
          {item.description && (
            <div className='item-description'>{item.description}</div>
          )}
        </div>
        <div className='item-price'>
          {item.priceType == 0 && (
            <div>
              <b>{item.price}</b>
              <span className='currency-icon'>₼</span>
            </div>
          )}
          {item.priceType == 1 &&
            item.prices.split('##').map(ip => {
              return (
                <div className='price-types'>
                  <div className='type'>{ip.split('**')[0]}</div>
                  <div className='price'>
                    <b>{ip.split('**')[1]}</b>
                    <span className='currency-icon'>₼</span>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      {modalState && (
        <div className='item-modal-container'>
          <div className='item-modal'>
            {loading && <div className="loading">Yüklənir...</div>}
              <div className='content'>
              <div className='modal-header'>
                <div className='item-name'>{item.name}</div>
                <div
                  className='close-modal'
                  onClick={() => setModalState(false)}
                >
                  &times;
                </div>
              </div>
              {showImages==1 && <img src={`${Variables.base_url}/api/images/business/medium/${item.imageUrl}`} onLoad={()=>setLoading(false)}/>}
              <div className='modal-footer'>
                <div className={`item-details${item.description?' item-has-description':''}`}>
                  {item.description && <div className='item-description'>{item.description}</div>}
                  <div className={`item-price`}>
                    {item.priceType == 0 && (
                      <div>
                        <b>{item.price}</b>
                        <span className='currency-icon'>₼</span>
                      </div>
                    )}
                    {item.priceType == 1 &&
                      item.prices.split('##').map(ip => {
                        return (
                          <div className='price-types'>
                            <div className='type'>{ip.split('**')[0]}</div>
                            <div className='price'>
                              <b>{ip.split('**')[1]}</b>
                              <span className='currency-icon'>₼</span>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Item
