import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import Category from './_Components/Category.js'
import './Menu.css'
import CategoryLine from './_Components/CategoryLine.js'
import img from '../../Assets/Icons/chevron.png'
import call from '../../Methods.js'
import Variables from '../../global.js'
import Loading from '../Client/_Components/Loading.js'
import { changeProductImageVisibility } from '../../store/menuSlice.js'


function Menu () {
  const upButtonRef = useRef()
  const { title } = useParams()
  const [menuData, setMenuData] = useState(null)
  const [loading, setLoading] = useState(true)
  let showImages = useSelector(state => state.menu.showProductImages)
  const dispatch = useDispatch()

  const getData = async () => {
    const response = await call.menu.getMenu({ title: title })
    if (response) {
      setMenuData(response)
      dispatch(changeProductImageVisibility({showProductImages:response.showProductImages}));
    }
  }

  useEffect(() => {
    if (title) {
      setLoading(true)
      getData()
      setLoading(false)
    }
  }, [title])

  window.addEventListener('scroll', function () {
    let pageHeight = document.body.scrollHeight
    let scrollY = window.scrollY
    let windowHeight = window.innerHeight
    if (scrollY > windowHeight * 1.2) {
      upButtonRef.current.style.display = 'flex'
    } else {
      upButtonRef.current.style.display = 'none'
    }
  })

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  if (loading) return <Loading />
  return (
    <div id='menu-page'>
      {menuData && (
        <>
          <div className='business-header-banner'>
            {menuData && (
              <img
                className='banner-background'
                src={
                  menuData.bannerImageUrl
                    ? `${Variables.base_url}/api/images/business/large/${menuData.bannerImageUrl}`
                    : '/Images/menyumuz-online-logo.png'
                }
              />
            )}
            <div className='top-layer'>
              <div className='header-info'>
                <div className='business-logo'>
                  {menuData && (
                    <img
                      src={
                        menuData.logoUrl
                          ? `${Variables.base_url}/api/images/business/medium/${menuData.logoUrl}`
                          : '/Images/menyumuz-online-logo.png'
                      }
                      alt='business logo'
                    />
                  )}
                </div>
                <h1 className='business-name'>{menuData.businessName}</h1>
                <p className='business-address'>{menuData.businessAddress}</p>
              </div>
            </div>
          </div>

          <div className='menu-container'>
            {menuData.categories && (
              <CategoryLine categories={menuData.categories} />
            )}
            {menuData.categories &&
              menuData.categories.map(c => {
                return <Category key={c.order+c.name} category={c} />
              })}
            <div onClick={scrollTop} ref={upButtonRef} className='up-button'>
              <img className='top' src={img} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Menu
