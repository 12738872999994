import { useState,useEffect } from 'react'
import Input from '../Components/Input.js'
import call from '../Methods.js'
import { use } from '../Helpers/Helpers.js'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, changeLogState } from '../store/authSlice.js'

function Logout () {
  const navigate = useNavigate()
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const dispatch = useDispatch()
  const token = use.token();

  const userLogout = async () => {
    const response = await call.forLoggedUser.auth.logout(token);
    localStorage.removeItem('mo_token')
    navigate('/login', { replace: true })
    dispatch(changeLogState({loggedIn:false}));
  }

  useEffect(()=>{
    if(loggedIn){
      userLogout();
    }else{
      localStorage.removeItem('mo_token')
      navigate('/login', { replace: true })
      dispatch(changeLogState({loggedIn:false}));
    }
  },[])

  return (
    <div>Çıxış edilir...</div>
  )
}

export default Logout
