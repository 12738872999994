import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Input from './_Components/Input'
import call from '../../Methods'
import Variables from '../../global'
import { use } from '../../Helpers/Helpers'
import { useSelector } from 'react-redux'
import Loading from './_Components/Loading'

function Items () {
  const [items, setItems] = useState([])
  const [category, setCategory] = useState(null)
  const { id, categoryId } = useParams()
  const [modalState, setModalState] = useState(id ? true : false)
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(true)

  const loadData = async () => {
    let itemResponse = await call.forLoggedUser.item.getItems({
      categoryId: categoryId
    })
    itemResponse = itemResponse.map((i, index) => ({
      ...i,
      prices:
        i.priceType == 1 && i.prices
          ? use.convertPriceDataToArray(i.prices)
          : [],
      price: i.priceType == 0 ? i.price : null
    }))
    setItems(itemResponse)
  }

  const loadCategory = async () => {
    let response = await call.forLoggedUser.category.getCategory({
      id: categoryId
    })
    setCategory(response)
  }

  useEffect(() => {
    if (!modalState) {
      setLoading(true)
      if (categoryId) {
        loadCategory()
      }
      loadData()
      setModalState(false)
      setLoading(false)
    }
  }, [modalState])

  useEffect(() => {
    setLoading(true)
    if (!id) {
      loadData()
      document.body.style.overflowY = 'auto'
    } else {
      document.body.style.overflowY = 'hidden'
    }
    setLoading(false)
  }, [id])


  const openModal = (categoryId=null, itemId=null) => {
    setModalState(true)
    categoryId
      ? navigate(`/client/categories/${+categoryId}/items/${itemId}`, {
          replace: true
        })
      : navigate(`/client/items/${itemId}`, { replace: true })
  }

  
  if(loading) return <Loading/>
  return (
    <div className='items-page'>
      <div className='items-list'>
        {category && (
          <h1 className='page-header'>
            <span className='left-container'>
              <Link id='previous-page' to={`/client/categories/`}></Link>
              {category.name}
            </span>
            <Link
              to={`/client/categories/${categoryId}/items/new`}
              id='add-new'
              onClick={() => setModalState(true)}
            >
              Yeni məhsul əlavə et
            </Link>
          </h1>
        )}
        {!category && (
          <h1 className='page-header'>
            <span className='left-container'>
              <Link id='previous-page' to={`/client/business/`}></Link>
              Bütün məhsullar
            </span>
            <Link
              to={`/client/items/new`}
              id='add-new'
              onClick={() => setModalState(true)}
            >
              Yeni məhsul əlavə et
            </Link>
          </h1>
        )}
        <table className='admin-table'>
          <thead>
            <tr>
              <th>
                <div>№</div>
              </th>
              <th>
                <div>Kateqoriya</div>
              </th>
              <th>
                <div>Şəkil</div>
              </th>
              <th>
                <div>Məhsulun adı</div>
              </th>
              <th>
                <div>Sıralama</div>
              </th>
              <th>
                <div>Qiyməti</div>
              </th>
              <th>
                <div>Funksiyalar</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items
              .sort((a, b) =>
                categoryId ? a.order - b.order : a.categoryId - b.categoryId
              )
              .map((i, index) => {
                return (
                  <tr
                    key={i.id}
                    className={`item${i.statusId <= 0 ? ' deactive' : ''}`}
                  >
                    <td width='8%'>{index + 1}</td>
                    <td width='12%'>
                      <span className='category-label label'>
                        {i.categoryName}
                      </span>
                    </td>
                    <td width='20%' className='image'>
                      <img
                        src={`${Variables.base_url}/api/Images/Business/Thumbnail/${i.imageUrl}`}
                      />
                    </td>
                    <td width='25%'>{i.name}</td>
                    <td width='8%'>{i.order}</td>
                    <td style={{ fontSize: '13px' }} width='12%'>
                      {i.priceType == 0
                        ? `${i.price.toFixed(2)} ₼`
                        : i.prices
                            ?.map(_i => `${parseFloat(_i.price).toFixed(2)} ₼`)
                            .join(' | ')}{' '}
                    </td>
                    <td width='20%' className='actions'>
                      <button
                        type='button'
                        className='action-button edit'
                        onClick={() => openModal(categoryId, i.id)}
                      >
                        🖊 Düzəliş
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <ItemModal itemId={id} categoryId={categoryId} menuId={user?.menuId} />
    </div>
  )
}

function ItemModal ({ itemId = null, categoryId = null, menuId = null }) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [item, setItem] = useState(null)
  const [categoryList, setCategoryList] = useState([])

  const fileInputRef = useRef(),
    formRef = useRef(),
    previewImageRef = useRef()

  if (itemId != null && typeof +itemId !== 'number') {
    itemId = 'new'
  }

  const loadData = async () => {
    setLoading(true)
    if (itemId && itemId != 'new') {
      const itemResponse = await call.forLoggedUser.item.getItem({ id: itemId })
      if (itemResponse) {
        let _prices =
          itemResponse.priceType == 1 && itemResponse.prices
            ? use.convertPriceDataToArray(itemResponse.prices)
            : []
        itemResponse.prices = _prices
        setItem(itemResponse)
      } else {
        navigate(`/client/categories/${categoryId}/items`, { replace: true })
      }
    } else {
      setItem({ id: '',imageUrl:'menyumuz-online-logo.png' })
    }

    const categoryResponse = await call.forLoggedUser.category.getCategories({
      menuId: menuId
    })
    setCategoryList(
      categoryResponse.map(c => {
        return { text: c.name, value: c.id }
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    if (itemId != 'new') {
      loadData()
    } else {
      formRef.current.reset()
      setItem({ id:'', imageUrl:'menyumuz-online-logo.png' })
    }
  }, [itemId])

  const updateImage = () => {
    fileInputRef.current.click()
  }

  const changePreviewImage = async e => {
    setLoading(true)
    let data = await use.getImageObject(e)
    let _item = item ? item : {}
    _item.image = data
    setItem(_item)
    setLoading(false)
  }

  const saveData = async itemId => {
    setLoading(true)
    let _item,
      itemResponse,
      prices = [],
      pricesStr
    if (item?.priceType == 1) {
      let _names = document.querySelectorAll('[name="priceTypeName[]"]')
      let _prices = document.querySelectorAll('[name="priceTypePrice[]"]')

      _names.length == _prices.length &&
        _names.forEach((n, i) => {
          prices.push(`${n.value}**${_prices[i].value}`)
        })
      pricesStr = prices.join('##')
    }
    _item = {
      name: use.getInputValueByName('name'),
      description: use.getInputValueByName('description'),
      order: use.getInputValueByName('order'),
      priceType: use.getInputValueByName('priceType') || 0,
      price: item?.priceType == 1 ? null : use.getInputValueByName('price'),
      prices: item?.priceType == 1 ? pricesStr : null,
      categoryId: use.getInputValueByName('categoryId'),
      statusId: use.getInputValueByName('statusId'),
      image: item?.image
    }

    if (itemId!='new') {
      _item.id = itemId
      itemResponse = await call.forLoggedUser.item.update(_item)
    } else {
      _item.id = null
      _item.imageUrl = _item.image ? null : 'menyumuz-online-logo.png'
      itemResponse = await call.forLoggedUser.item.add({
        ..._item,
        menuId: menuId
      })
    }

    if (itemResponse) {
      setItem({
        ...itemResponse,
        prices:
          itemResponse.priceType == 1
            ? use.convertPriceDataToArray(itemResponse.prices)
            : []
      })
    }
    setLoading(false)
  }

  const deleteData = async itemId => {
    let sure = window.confirm('Əminsinizmi?')
    if (sure) {
      const response = await call.forLoggedUser.item.update({
        id: itemId,
        statusId: -1
      })
      if (response) {
        alert('Silindi')
        navigate(`/client/categories/${categoryId}/items`, { replace: true })
      }
    }
  }

  return (
    <div id='item-edit' className={`modal-container ${itemId!=null ? 'open' : null}`}>
      <div className='item-form side-modal'>
        {loading && <div className='side-modal-loading'>Gözləyin...</div>}
        {item && (
          <div className='form-container'>
            <header className='side-modal-header'>
              <span
                className='close-side-modal'
                onClick={() => {
                  categoryId
                    ? navigate(`/client/categories/${categoryId}/items`, {
                        replace: true
                      })
                    : navigate(`/client/items`, { replace: true })
                }}
              >
                &times;
              </span>
              <h3>{item?.name}</h3>
            </header>
            <form ref={formRef} className='form row'>
              <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type='file'
                accept='image/png, image/jpeg'
                onChange={changePreviewImage}
              />
              <div className='image-input-container icon-container'>
                <div className='image' onClick={updateImage}>
                  <div className='edit-layer'>
                    <span className='pen'>✎</span>
                  </div>
                  {!loading && (
                  <img
                    ref={previewImageRef}
                    src={
                      item.image?.url || `${Variables.base_url}/api/Images/Business/Medium/${item.imageUrl}`
                    }
                    alt={item.name}
                  />
                )}
                </div>
              </div>
              {item && categoryList && categoryList.length > 0 && (
                <Input
                  type={'select'}
                  label={'Kateqoriya'}
                  fieldName={'categoryId'}
                  data={categoryList}
                  selected={item?.categoryId}
                  value={item?.categoryId}
                />
              )}
              <Input
                value={item?.name}
                fieldName={'name'}
                label={'Məhsulun adı'}
                type={'text'}
                containerStyle={{ width: '60%', display: 'inline-block' }}
              />
              <Input
                label={'Görünmə sırası'}
                type={'number'}
                fieldName={'order'}
                value={item?.order}
                style={{ textAlign: 'center' }}
                containerStyle={{ width: '20%', display: 'inline-block' }}
              />

              <Input
                label={'Aktiv'}
                type={'checkbox'}
                fieldName={'statusId'}
                containerStyle={{
                  width: '20%',
                  textAlign: 'center',
                  display: 'inline-block'
                }}
                checked={item?.statusId == 1}
                eventHandler={[
                  {
                    type: 'change',
                    handler: () =>
                      setItem(item => ({
                        ...item,
                        statusId: item.statusId == 1 ? 0 : 1
                      }))
                  }
                ]}
              />
              <Input
                value={item?.description}
                fieldName={'description'}
                label={'Əlavə məlumat'}
                type={'text'}
              />
              <div className='pricing-types'>
                <Input
                  value={0}
                  type={'radio'}
                  label={'Tək qiymət'}
                  selected={item == null || item.priceType == 0}
                  fieldName={'priceType'}
                  style={{ flexGrow: '1' }}
                  containerStyle={{ width: '33%' }}
                  eventHandler={[
                    {
                      type: 'click',
                      handler: () =>
                        setItem(item => ({ ...item, priceType: 0 }))
                    }
                  ]}
                />
                <Input
                  value={1}
                  type={'radio'}
                  label={'Bir neçə qiymət'}
                  fieldName={'priceType'}
                  selected={item?.priceType == 1}
                  style={{ flexGrow: '1' }}
                  containerStyle={{ width: '33%' }}
                  eventHandler={[
                    {
                      type: 'click',
                      handler: e => {
                        setItem(item => ({
                          ...item,
                          priceType: 1
                        }))
                      }
                    }
                  ]}
                />
              </div>
              {(!item || item?.priceType == 0) && (
                <>
                  <Input
                    value={item?.price}
                    label={'Qiyməti'}
                    type={'number'}
                    icon={'₼'}
                    fieldName={'price'}
                    style={{ textAlign: 'left' }}
                    containerStyle={{ width: '33%', display: 'inline-block' }}
                  />
                </>
              )}
              {console.log('_prices', item?.priceType, item?.prices)}
              {item?.priceType == 1 && (
                <>
                  <div className='added-prices'>
                    {item?.prices &&
                      [...item?.prices].map((ip, index) => {
                        console.log('_price', ip, index, item.prices)
                        return (
                          <React.Fragment key={index}>
                            <Input
                              containerStyle={{ width: '50%' }}
                              value={ip.name}
                              type='text'
                              label={'Ad'}
                              fieldName={'priceTypeName[]'}
                            />
                            <Input
                              containerStyle={{ width: '40%' }}
                              value={ip.price}
                              type='number'
                              icon={'₼'}
                              label={'Qiymət'}
                              fieldName={'priceTypePrice[]'}
                              eventHandler={[
                                {
                                  type: 'blur',
                                  handler: e => {
                                    //e.target.value = Number(e.target.value).toPrecision(e.target.value.toString().length+2);
                                  }
                                }
                              ]}
                            />
                            <span
                              className='remove-price'
                              onClick={() => {
                                setItem(item => ({
                                  ...item,
                                  prices: item.prices.filter(
                                    ip => item.prices.indexOf(ip) != index
                                  )
                                }))
                              }}
                            >
                              &times;
                            </span>
                          </React.Fragment>
                        )
                      })}
                  </div>
                  <div className='button-box'>
                    <button
                      type='button'
                      onClick={() => {
                        item.prices.push({ name: 'Ad', price: 0 })
                        setItem(item => ({
                          ...item,
                          prices: item.prices
                        }))
                      }}
                      className='button button-default add-price'
                    >
                      + Qiymət əlavə et
                    </button>
                  </div>
                </>
              )}

              <div className='button-box'>
                <button
                  type='button'
                  className='button button-back'
                  onClick={() => {
                    categoryId
                      ? navigate(`/client/categories/${categoryId}/items`, {
                          replace: true
                        })
                      : navigate(`/client/items`, { replace: true })
                  }}
                >
                  İmtina
                </button>
                <button
                  type='button'
                  className='button button-submit'
                  onClick={() => saveData(itemId)}
                >
                  Təsdiq
                </button>
              </div>
              {itemId != 0 && (
                <div
                  className='button-box'
                  style={{ width: '100%', display: 'inline-block' }}
                >
                  <button
                    type='button'
                    className='button button-delete'
                    onClick={() => deleteData(itemId)}
                  >
                    Məhsulu sil
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default Items
