import { useEffect, useRef, useState } from 'react'
import Input from './_Components/Input.js'
import call from '../../Methods.js'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Variables from '../../global.js'
import { use } from '../../Helpers/Helpers.js'
import { useSelector } from 'react-redux'
import Loading from './_Components/Loading.js'

function Business () {
  const [loading, setLoading] = useState(true)
  const logoRef = useRef(null)
  const bannerRef = useRef(null)
  const [menuData, setMenuData] = useState({})
  const { loggedIn, user } = useSelector(state => state.auth)
  const navigate = useNavigate()

  const loadData = async () => {
    setLoading(true)
    const menuResponse = await call.forLoggedUser.menu.getSingle({
      id: user.menuId
    })
    if (menuResponse) {
      setMenuData(menuResponse)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loggedIn) {
      loadData()
    } else {
      navigate(`/login`, { replace: true })
    }
  }, [loggedIn])

  const updateImage = ref => {
    ref.current.click()
  }

  const changePreviewImage = async (e, field, maxSizeInMbs) => {
    setLoading(true)
    let _menuData = menuData ? menuData : {}
    let _image = await use.getImageObject(e)
    if (maxSizeInMbs && _image.size > maxSizeInMbs * 1048576) {
      alert(`Şəkil ${maxSizeInMbs} MB-dan artıq olmamalıdır.`)
      setLoading(false)
      return
    }
    _menuData[field] = _image
    setMenuData(_menuData)
    setLoading(false)
  }

  const saveData = async () => {
    setLoading(true)
    let _menuData = menuData
    _menuData.businessName = use.getInputValueByName('name')
    _menuData.businessAddress = use.getInputValueByName('address')
    _menuData.showProductImages = use.getInputValueByName('showProductImages')
    setMenuData({ ..._menuData })
    const editResponse = await call.forLoggedUser.menu.edit(_menuData)

    if (editResponse) {
      setMenuData(editResponse)
    }
    setLoading(false)
  }

  return (
    <div className='business-page'>
      <aside>
        {menuData && (
          <div className='menu-boxes'>
            <div
              key={use.newUid()}
              className={`menu-box${menuData.statusId <= 0 ? ' deactive' : ''}`}
            >
              <div className='menu-title'>{menuData.title}</div>
              <div className='qr-code'>
                <img
                  src={`https://quickchart.io/qr?text=${Variables.base_url}/menu/${menuData.title}&size=120`}
                />
              </div>

              <div className='links'>
                <Link to={`/client/categories`}>Kateqoriyalar</Link>
                <Link to={`/client/items`}>Bütün Məhsullar</Link>
              </div>
            </div>
          </div>
        )}
      </aside>
      {menuData && (
        <div className='form-container'>
          <form className='form'>
            {loading && <Loading />}
            <input
              ref={bannerRef}
              style={{ display: 'none' }}
              type='file'
              accept='image/png, image/jpeg'
              onChange={e => changePreviewImage(e, 'bannerImage', 5)}
            />
            <div className='image-input-container banner-container'>
              <div className='image' onClick={() => updateImage(bannerRef)}>
                <div className='edit-layer'>
                  <span className='pen'>✎</span>
                </div>
                {!loading && (
                  <img
                    src={
                      menuData.bannerImage?.isNew
                        ? menuData.bannerImage.url
                        : `${Variables.base_url}/api/Images/Business/Large/${menuData.bannerImageUrl}`
                    }
                    alt={menuData.name}
                  />
                )}
              </div>
            </div>
            <input
              ref={logoRef}
              style={{ display: 'none' }}
              type='file'
              accept='image/png, image/jpeg'
              onChange={e => changePreviewImage(e, 'logo', 2)}
            />
            <div className='image-input-container logo-container'>
              <div className='image' onClick={() => updateImage(logoRef)}>
                <div className='edit-layer'>
                  <span className='pen'>✎</span>
                </div>
                {!loading && (
                  <img
                    src={
                      menuData.logo?.isNew
                        ? menuData.logo.url
                        : `${Variables.base_url}/api/Images/Business/Medium/${menuData.logoUrl}`
                    }
                    alt={menuData.businessName}
                  />
                )}
              </div>
            </div>
            <Input
              fieldName='name'
              value={menuData.businessName}
              label={'Məkanın adı'}
              type={'text'}
            />
            <Input
              fieldName='address'
              value={menuData.businessAddress}
              label={'Ünvan'}
              type={'textarea'}
            />

            <Input
              fieldName='showProductImages'
              type={'checkbox'}
              label={'Müştəriyə məhsul şəkillərini göstər'}
              checked={menuData.showProductImages == 1}
              eventHandler={[
                {
                  type: 'change',
                  handler: () =>
                    setMenuData(menuData => ({
                      ...menuData,
                      showProductImages: menuData.showProductImages == 1 ? 0 : 1
                    }))
                }
              ]}
            />
            <div className='button-box'>
              <button
                type='button'
                className='button button-submit'
                onClick={saveData}
              >
                {loading ? 'Bir saniyə...' : 'Saxla'}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default Business
