import React from 'react'

function CategoryLine ({ categories }) {
  return (
    <React.Fragment>
      <div className='category-line'>{categories.map(c => {
        return <a key={c.order+c.name} href={`#${c.name.replace(' ','-')}`} className="category-button">{c.name}</a>
      })}
      </div>
    </React.Fragment>
  )
}

export default CategoryLine
