import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    showProductImages:0
  },
  reducers: {
    changeProductImageVisibility:(state,action)=>{
      state.showProductImages = action.payload.showProductImages;
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeProductImageVisibility } = menuSlice.actions

export default menuSlice.reducer