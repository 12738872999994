import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Item from './Item.js'


function Category ({ category }) {
  const showImages = useSelector(state => state.menu.showProductImages);

  return (
    <React.Fragment>
      <div id={category.name.replace(' ','-')} className='category-header'>{category.name}</div>
      <div className={`category-items${showImages==0? ' no-image':''}`}>
        {category.items.map(i => {
          return <Item key={i.order+i.name} item={i} />
        })}
      </div>
    </React.Fragment>
  )
}

export default Category
