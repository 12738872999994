import { useEffect, useState } from 'react'
import Input from '../Components/Input.js'
import call from '../Methods.js'
import { use } from '../Helpers/Helpers.js'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, changeLogState } from '../store/authSlice'
import InfoModal from '../Components/InfoModal.js';
import ValidateInput from '../Helpers/ValidationDefaults.js'
import Variables from '../global.js'

function Login () {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [userData, setUserData] = useState({ userName: null, password: null })
  const [infoModalIsVisible,setInfoModalIsVisible] = useState(false);
  const token = use.token()
  const loggedIn = useSelector(state => state.auth.loggedIn)
  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState({
    userName: '',
    password: ''
  })
  const ValidateAs = {
    userName: ['required', 'userName'],
    password: ['required']
  }

  let _formValues = {
    userName: '',
    password: ''
  }

  const userLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    let validationResults = [];
    let _loginInfo = {
      userName: use.getInputValueByName('userName'),
      password: use.getInputValueByName('password')
    };
    
    
    [...e.target.elements].forEach(input => {
      if(input.type==='submit'){
        return;
      }
      _formValues[input.name] = input.value
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )
        
        let validation = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(validation)

        if (!validation.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = validation?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if(validationResult){
      const response = await call.auth.login(_loginInfo);
      if (response?.token) {
        localStorage.setItem('mo_token', response.token)
        dispatch(changeLogState({ loggedIn: true }))
        navigate('/redirect', { replace: true })
      }else{
        alert('Giriş uğursuzdur! Xahiş olunur, bir daha cəhd edin')
      }
    }
    
    setLoading(false)
  }


  useEffect(() => {
    const enterKeyPressHandler = (e)=>{
      if(e.key==='Enter'){
        userLogin();
      }
    }
    if (token) {
      navigate('/redirect', { replace: true });
    }

    document.addEventListener('keypress',enterKeyPressHandler,{test:1})
    return ()=>document.removeEventListener('keypress',enterKeyPressHandler,{test:1})    
  }, [])

  return (
    <div className='login-page'>
      <div className='login-page-content'>
        <div className='logo'>
          <img src='logo192.png' />
        </div>
        <form className='login-form form' onSubmit={(e)=>userLogin(e)}>
          <Input
            label={'İstifadəçi adı'}
            type={'text'}
            fieldName={'userName'}
          />
          <Input label={'Şifrə'} type={'password'} fieldName={'password'} />
          <div className='button-box'>
            {loading ? (
              <button
                type='button'
                className='button loading'
              >
                Bir saniyə...
              </button>
            ) : (
              <button
                type='submit'
                className='button button-primary'
              >
                Giriş
              </button>
            )}
          </div>
          <div className="login">
            <p onClick={()=>setInfoModalIsVisible(true)} className="forgot-credentials">Giriş məlumatlarımı unutmuşam</p>
            <Link className='default-link' to={'/'}>Əsas səhifəyə qayıt</Link>
          </div>
          {infoModalIsVisible && <InfoModal closer={()=>setInfoModalIsVisible(false)} header={'Məlumat'} type={'info'} size={'M'}>
              <p>Xahiş olunur, +99450 9920010 telefon nömrəsi ilə əlaqə saxlayın.</p>
            </InfoModal>}
        </form>
      </div>
    </div>
  )
}

export default Login
