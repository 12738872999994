import axios from "axios";
import call from "../Methods";
import { useEffect } from "react";
import { use } from "../Helpers/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {login,logout,changeLogState,changeUserInfo} from "../store/authSlice";

function Redirect(){
    const token = use.token();
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const loggedIn = useSelector((state)=>state.auth.loggedIn);
    const location = useLocation();
    console.log(location);

    const redirectToDashboard = async() =>{
        const user = await call.auth.checkToken(token);
        
        if(user?.userId){
            dispatch(changeLogState({loggedIn:true}));
            dispatch(changeUserInfo({user:user}));
            if(user.roleName=='Admin'){
                navigate('/admin/dashboard',{replace:true})
            }else if(user.roleName=='Client'){
                navigate(`/client/business`,{replace:true})
            }else{
                navigate(`/`,{replace:true})
            }
        }else{
            navigate(`/login`,{replace:true});
            localStorage.removeItem('mo_token');
            dispatch(changeLogState({loggedIn:false}));
        }
    }

    useEffect(()=>{
        if(token){
            redirectToDashboard();
        }else{
            navigate('/login',{replace:true});
        }
    },[])

    return <>Bir saniyə...</>
}

export default Redirect;