import { useEffect, useRef, useState } from 'react'
import Input from './_Components/Input'
import { Link, useNavigate, useParams } from 'react-router-dom'
import call from '../../Methods.js'
import { use } from '../../Helpers/Helpers.js'
import Variables from '../../global.js'
import { useSelector } from 'react-redux'
import Loading from './_Components/Loading.js'


function Categories () {
  const { id } = useParams()
  const [modalState, setModalState] = useState(false)
  const [categories, setCategories] = useState(null)
  const { loggedIn, user } = useSelector(state => state.auth)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true);

  const loadData = async () => {
    let response = await call.forLoggedUser.category.getCategories({
      menuId: user.menuId
    })
    if(response){
      setCategories(response)
    }
  }

  useEffect(() => {
    if (!modalState) {
      setLoading(true);
      loadData()
      setModalState(false);
      setLoading(false);
    }
  }, [modalState]);


  useEffect(() => {
    if (!id) {
      loadData()
      document.body.style.overflowY = 'auto'
    } else {
      document.body.style.overflowY = 'hidden'
      //document.addEventListener('keyup',closeModal,true);
    }
    //return ()=>document.removeEventListener('keyup',closeModal,true)
  }, [id])



  if(loading) return <Loading/>
  return (
    <div className='categories-page client-page'>
      <div className='categories-list'>
        <h1 className='page-header'>
        <span className="left-container">
            <Link id="previous-page" to={`/client/business/`}>
            </Link>
          Kateqoriyalar
          </span>
          <Link
            to={`/client/categories/new`}
            id='add-new'
            onClick={() => setModalState(true)}
          >
            Yeni kateqoriya əlavə et
          </Link>
        </h1>
        <table className='admin-table'>
          <thead>
            <tr>
              <th>
                <div>№</div>
              </th>
              <th>
                <div>Kateqoriyanın adı</div>
              </th>
              <th>
                <div>Şəkil</div>
              </th>
              <th>
                <div>Sıralama</div>
              </th>
              <th>
                <div>Funksiyalar</div>
              </th>
            </tr>
          </thead>
          {categories && (
            <tbody>
              {categories
                .sort((a, b) => +a.order - +b.order)
                .map((c, index) => {
                  return (
                    <tr
                      key={use.newUid()}
                      className={`item${c.statusId <= 0 ? ' deactive' : ''}`}
                    >
                      <td width='12%' className='order'>
                        {index + 1}
                      </td>
                      <td width='30%' className='name'>
                        {c.name}
                      </td>
                      <td width='20%' className='image'>
                        <img
                          src={`${Variables.base_url}/api/Images/Business/thumbnail/${c.imageUrl}`}
                        />
                      </td>
                      <td width='12%' className='order'>
                        {c.order}
                      </td>
                      <td width='38%' className='actions'>
                        <Link
                          className='action-button edit'
                          to={`/client/categories/${c.id}`}
                          onClick={() => setModalState(true)}
                        >
                          🖊 Düzəliş
                        </Link>
                        <Link
                          to={`/client/categories/${c.id}/items`}
                          className='action-button products'
                        >
                          {'Məhsullar'}
                        </Link>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          )}
        </table>
      </div>
      <CategoryModal categoryId={id} menuId={user.menuId} />
    </div>
  )
}

function CategoryModal ({ categoryId = null, menuId=null }) {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [category, setCategory] = useState({})
  const imageRef = useRef(),
    formRef = useRef(),
    previewImageRef = useRef()

  if (categoryId != null && typeof +categoryId !== 'number') {
    categoryId = 'new'
  }
  
  const loadData = async () => {
    setLoading(true)
    if (categoryId != 'new') {
      const response = await call.forLoggedUser.category.getCategory({
        id: categoryId
      })
      if (response) {
        setCategory(response);
      } else {
        navigate('/client/categories', { replace: true })
      }
    } else {
      setCategory({id:'',imageUrl:'menyumuz-online-logo.png'})
    }
    setLoading(false)
  }

  useEffect(() => {
    if (categoryId != null) {
      loadData().catch(err => console.log(err))
    } else {
      formRef.current.reset()
    }
  }, [categoryId])

  const updateImage = () => {
    imageRef.current.click()
  }

  const changePreviewImage = async e => {
    setLoading(true)
    let data = await use.getImageObject(e)
    let _category = category
    _category.image = data
    setCategory(_category)
    setLoading(false)
  }

  const saveData = async categoryId => {
    setLoading(true)
    let _category, categoryResponse
    
    _category = {
      name: use.getInputValueByName('name'),
      order: use.getInputValueByName('order'),
      statusId: use.getInputValueByName('statusId'),
      image: category.image
    }

    if (categoryId != 'new') {
      _category.id = categoryId
      console.log(category,categoryId)
      categoryResponse = await call.forLoggedUser.category.update(_category)
    } else {
      _category.id = null;
      _category.imageUrl = _category.image? null : 'menyumuz-online-logo.png';
      categoryResponse = await call.forLoggedUser.category.add({..._category,menuId:menuId})
    }
    setLoading(false)
    if (categoryResponse) {
      setCategory(categoryResponse)
      //navigate('/client/categories', { replace: true })
    }
  }

  const deleteData = async categoryId => {
    let sure = window.confirm('Əminsinizmi?')
    if (sure) {
      const response = await call.forLoggedUser.category.update({
        id: categoryId,
        statusId: -1
      })
      if (response) {
        alert('Silindi')
        navigate('/client/categories', { replace: true })
      }
    }
  }

  const closeModal = (e) => {
    console.log('escape')
    if(e.key=='Escape'){
      navigate('/client/categories',{replace:true})
    }
  }

  useEffect(()=>{
    document.addEventListener('keyup',closeModal)
    
    return document.removeEventListener('keyup',closeModal)
  },[])



  return (
    <>
      <div
        id='category-edit'
        className={`modal-container ${categoryId != null ? 'open' : null}`}
      >
        <div className='category-form side-modal'>
        {loading && <div className='side-modal-loading'>Gözləyin...</div>}
        {category && <div className="form-container">
          <header className='side-modal-header'>
            <span
              className='close-side-modal'
              onClick={() => {
                navigate('/client/categories', { replace: true })
              }}
            >
              &times;
            </span>
            <h3>{category?.name}</h3>
          </header>
          <form className='form row' ref={formRef}>
            <input
              name={''}
              ref={imageRef}
              accept={'image/png, image/jpeg'}
              style={{ display: 'none' }}
              type='file'
              onChange={e => changePreviewImage(e,'image',3)}
            />
            <div className='image-input-container icon-container'>
              <div className='image' onClick={()=>updateImage(imageRef)}>
                <div className='edit-layer'>
                  <span className='pen'>✎</span>
                </div>                
                {!loading && (
                  <img
                    ref={previewImageRef}
                    src={
                      category.image?.url || `${Variables.base_url}/api/Images/Business/Medium/${category.imageUrl}`
                    }
                    alt={category.name}
                  />
                )}
                
              </div>
            </div>
            <Input
              fieldName={'name'}
              label={'Kateqoriyanın adı'}
              type={'text'}
              value={category?.name}
            />
            <Input
              label={'Görünmə sırası'}
              fieldName={'order'}
              type={'number'}
              style={{ textAlign: 'center' }}
              containerStyle={{ width: '40%', display: 'inline-block' }}
              value={category?.order}
            />
            <Input
              label={'Aktiv'}
              type={'checkbox'}
              fieldName={'statusId'}
              containerStyle={{
                width: '40%',
                textAlign: 'center',
                display: 'inline-block'
              }}
              checked={category?.statusId==1}
              eventHandler={[
                {
                  type: 'change',
                  handler: () =>
                    setCategory(category => ({
                      ...category,
                      statusId: category.statusId == 1 ? 0 : 1
                    }))
                }
              ]}
            />
            <div className='button-box'>
              <button
                type='button'
                className='button button-back'
                onClick={() =>
                  navigate('/client/categories', { replace: true })
                }
              >
                İmtina
              </button>
              {loading ? (
                <button
                  type='button'
                  disabled
                  className='button button-loading'
                >
                  Bir saniyə...
                </button>
              ) : (
                <button
                  type='button'
                  className='button button-submit'
                  onClick={() => saveData(categoryId)}
                >
                  Təsdiq
                </button>
              )}
              {categoryId != 'new' && (
              <div
                className='button-box'
              >
                <button
                  type='button'
                  className='button button-delete'
                  onClick={() => deleteData(categoryId)}
                >
                  Sil
                </button>
              </div>
            )}
            </div>
          </form>
          </div>}
          
        </div>
      </div>
    </>
  )
}

export default Categories
