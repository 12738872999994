import { useState } from 'react'

function InfoModal ({ children, closer, header, type }) {
  
  return (
    <div className='info-modal-container' style={{opacity:1,zIndex:10}}>
      <div className={'info-modal'} style={{ width: `50%` }}>
        <div className='info-modal-header'>
          <h3>{header ? header : 'Məlumat'}</h3>
          <span onClick={closer} className='close-info-modal'>
            &times;
          </span>
        </div>
        <div className='info-modal-content'>{children}
        </div>
        <div className='button-box'>
            <button type="button" onClick={closer} className="button button-primary">Oldu</button>
            </div>
      </div>
    </div>
  )
}

export default InfoModal
