import { useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { use } from '../../Helpers/Helpers';


function Layout () {
  const navigate = useNavigate();
  const {user} = useSelector((state)=>state.auth.user);
  const loggedIn = useSelector((state)=>state.auth.loggedIn);
  
  useEffect(()=>{
    if(!loggedIn){
      navigate('/redirect',{state:{'test':'test'},replace:true})
    }
  },[])
  
  if(!loggedIn) return null;
  return (
    <>
      <header id='client-header'>
        <div className='logo'>
          <img src={'/Images/menyumuz-online-logo.png'} />
        </div>
        <ul>
          <li>
            <Link to='/client/business'>Menyumuz</Link>
          </li>
          <li>
            <Link to='/logout'>Çıxış</Link>
          </li>
        </ul>
      </header>
      <div id="client-page">
        <Outlet />
      </div>      
      <footer id='client-footer'>
        <p>Bütün hüquqları qorunur. © Kodart</p>
      </footer>
    </>
  )
}
export default Layout
