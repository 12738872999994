import './style.css'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Home from './Pages/Reader/Home.js'
import Menu from './Pages/Reader/Menu.js'
import Login from './Pages/Login'
import Logout from './Pages/Logout.js'
import Layout from './Pages/Client/Layout.js'
import Business from './Pages/Client/Business.js'
import Categories from './Pages/Client/Categories.js'
import Items from './Pages/Client/Items.js'
import Dashboard from './Pages/Client/Dashboard.js'
import AdminDashboard from './Pages/Admin/Dashboard.js';
import AdminLayout from './Pages/Admin/Layout.js';
import Businesses from './Pages/Admin/Businesses.js';
import { useSelector, useDispatch } from 'react-redux'
import Redirect from './Pages/Redirect.js'
import { use } from './Helpers/Helpers.js'


function App() {
  const token = use.token();
  return (
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='menu/:title' element={<Menu />} />
        <Route path='login' element={<Login/>} />
        <Route path='logout' element={<Logout />} />
        <Route path='redirect' element={<Redirect />} />
        <Route path='/client' element={<Layout/>}>
          <Route path='/client/dashboard' element={<Dashboard/>}/>
          <Route path='/client/business/:id?' element={<Business/>}/>
          <Route path='/client/categories/:id?' element={<Categories/>}/>
          <Route path='/client/categories/:categoryId/items/:id?' element={<Items/>}/>
          <Route path='/client/items/:id?' element={<Items/>}/>
        </Route>
        <Route path='/admin' element={<AdminLayout/>}>
          <Route path='/admin/dashboard' element={<AdminDashboard/>}/>
          <Route path='/admin/businesses/:id?' element={<Businesses/>}/>
          <Route path='/admin/businesses/:businessId/categories/:id?' element={<Items/>}/>
          <Route path='/admin/items/:id?' element={<Items/>}/>
        </Route>
      </Routes>
  )
}

export default App