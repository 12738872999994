import { createSlice } from '@reduxjs/toolkit'
import call from '../Methods';
import { use } from '../Helpers/Helpers';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: false,
    user:{}
  },
  reducers: {
    changeLogState:(state,action)=>{
      state.loggedIn = action.payload.loggedIn;
    },
    changeUserInfo:(state,action)=>{
      state.user = action.payload.user;
    },
    login: (state) => {
      state.loggedIn = true;
      console.log('test');
    },
    logout: (state) => {
      localStorage.removeItem('mo_token');
      state.loggedIn = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, changeLogState,changeUserInfo } = authSlice.actions

export default authSlice.reducer