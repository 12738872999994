import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom'

function Home () {
  return (
    <React.Fragment>
      <div className='home-page page'>
        <main className='page-content'>
          <img
            className='product-logo'
            src='/Images/menyumuz-online-logo.png'
          />
          <div className='product-name'>menyumuz.online</div>
          <div className='contact'>+994 50 992 00 10</div>
          <Link to={'/login'} className='button login-button'>
            Giriş
          </Link>
        </main>
      </div>
    </React.Fragment>
  )
}

export default Home
