export default function ValidateInput (
  name,
  types = [],
  value,
  errorMessage = ''
) {
  let validationResult = {
    name: name,
    result: true,
    message: 'success'
  }

  for (let i = 0; i < types.length; i++) {
    switch (types[i]) {
      case 'userName': {
        let PATTERN = /^[a-zA-Z][a-zA-Z0-9.-]{5,}$/g
        let MAX_LENGTH = 20
        let result = value.length <= MAX_LENGTH && PATTERN.test(value)
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'İstifadəçi adı doğru daxil edilməyib'
            : 'success'
        }
        break
      }
      case 'email': {
        let PATTERN = /^\S+@\S+\.\S+$/g
        let MAX_LENGTH = 254
        let result = value.length <= MAX_LENGTH && PATTERN.test(value)
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'E-poçt ünvanı düzgün daxil edilməyib.'
            : 'success'
        }
        break
      }
      case 'name': {
        let MIN_LENGTH = 2
        let MAX_LENGTH = 100
        let result = value.length <= MAX_LENGTH && value.length >= MIN_LENGTH
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'Lütfən, keçərli ad daxil edin (min:2, max:100 hərf)'
            : 'success'
        }
        break
      }
      case 'password': {
        let MIN_LENGTH = 7
        let MAX_LENGTH = 50
        let result = value.length <= MAX_LENGTH && value.length >= MIN_LENGTH
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'Minimum length is 7 symbols'
            : 'success'
        }
        break
      }
      case 'fullName': {
        let MIN_LENGTH = 3
        let MAX_LENGTH = 100
        let result = value.length <= MAX_LENGTH && value.length >= MIN_LENGTH
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'Please, type valid name (min:3, max:100 letters)'
            : 'success'
        }
        break
      }
      case 'digit': {
        let PATTERN = /\d+/g
        let result = PATTERN.test(value)
        validationResult = {
          name: name,
          result: result,
          message: !result ? errorMessage || 'Dəyər keçərli deyil' : 'success'
        }
        break
      }
      case 'nonzero': {
        let PATTERN = /[1-9][0-9]*/g
        let result = PATTERN.test(value)
        validationResult = {
          name: name,
          result: result,
          message: !result ? errorMessage || "0 daxil edilə bilməz" : 'success'
        }
        break
      }
      case 'phone': {
        let PATTERN =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g
        let result = PATTERN.test(value)
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'Daxil edilmiş dəyər formata uyğun deyil.'
            : 'success'
        }
        break
      }
      case 'required': {
        let result = !['', undefined, null].includes(value.trim())
        validationResult = {
          name: name,
          result: result,
          message: !result
            ? errorMessage || 'Bu xananın doldurulması vacibdir'
            : 'success'
        }
        break
      }
      default: {
        validationResult = {
          name: name,
          result: true,
          message: 'success'
        }
        break
      }
    }

    if (validationResult.result === false) {
      break
    }
  }
  return validationResult
}
